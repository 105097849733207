// Fixed the imageLoader function to include required parameters and return type.
export const imageLoader = ({
    src,
    width,
    quality,
  }: {
    src: string;
    width: number;
    quality?: number;
  }): string => {
    let seperator = "?";
    if (src.includes("?")) {
      seperator = "&";
    }
    if (
      !src.startsWith("https://cdn.dresden.vision") &&
      !src.startsWith("https://w.dresden.vision")
    ) {
      if (!src.startsWith("/")) {
        src = "/" + src;
      }
      src = "https://w.dresden.vision" + src;
    }
    return `${src}${seperator}w=${width}&q=${quality || 75}`;
  };
  