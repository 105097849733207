export const TAGGED_PRODUCTS = [
  {
    paths: [
      '/shop/prescription-glasses/beeswax',
      '/shop/blue-light-filter-glasses/beeswax',
      '/shop/prescription-sunglasses/beeswax',
      '/shop/non-prescription-sunglasses/beeswax',
      '/shop/frames/beeswax',
      '/shop/spare-parts/beeswax',
    ],
    backgroundColor: '#FF8700',
    textColor: '#FFFFFF',
    text: 'New',
    withStarIcon: false,
    availableRegions: ['au', 'nz', 'international', 'ca']
  },
];
