import { SVGProps } from 'react';

const Caret = ({
  width = 15,
  height = 9,
  fill = '#00AEC7',
  ...otherProps
}: SVGProps<SVGSVGElement>) => (
  <svg
    height={height + 'px'}
    width={width + 'px'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.07 8.627 5.514 7.072 0 1.555 1.555 0 7.07 5.515 12.585.001l1.556 1.555-5.515 5.515L7.07 8.627Z"
      fill={fill}
    />
  </svg>
);

export default Caret;
